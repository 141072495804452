<template>
  <base-dialog v-model="dialog" title="Painel de Ações" max-width="700" persistent transition="dialog-bottom-transition">
    <v-container>
      <v-row no-gutters>
        <v-col cols="12">
          <mf-select
            v-model="commandAction"
            item-value="value"
            item-text="text"
            :disabled="!selected.length"
            :items="commands"
            label="Ação/Comando"
            outlined
          ></mf-select>
        </v-col>
      </v-row>
      <div class="mt-3">
        <v-alert v-if="commandAction === 'reset_password'" dense type="info" class="text-center">
          Execute o comando "reset_password" somente para usuários externos. Senha padrão após reset: <strong>M3rc@f4cil123</strong>
        </v-alert>
      </div>
      <v-container fluid>
        <v-alert v-if="!selected.length" dense type="error" class="text-center">
          Certifique-se de selecionar os usuários antes de executar as ações
        </v-alert>
        <div v-else>
          <h4 style="text-align: center">Usuários:</h4>
          <v-row>
            <v-chip
              v-for="item in selected"
              :key="item._id"
              style="margin: 2px auto; justify-content: space-between"
              close
              @click:close="removeSelected(item._id)"
            >
              {{ item.login }}
            </v-chip>
          </v-row>
        </div>
      </v-container>
      <v-row no-gutters>
        <v-col cols="6">
          <mf-button color="error" class="mt-3" outlined label="Cancelar" @click="close"></mf-button>
        </v-col>
        <v-col cols="6">
          <v-row justify="end">
            <v-col cols="auto">
              <mf-button :disabled="!selected.length || !commandAction" label="Executar" color="primary" @click="triggerAction"></mf-button>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-dialog>
</template>

<script>
import { MUTATION_TRIGGER_ADMIN_ACTION } from '@/modules/session/graphql'
import BaseDialog from '../../../components/atomic-components/atoms/BaseDialog.vue'

export default {
  components: {
    BaseDialog
  },
  props: {
    dialog: Boolean,
    selected: Array
  },
  data: () => ({
    commands: [
      { value: 'logout_user_platform', text: 'Deslogar usuário da plataforma' },
      { value: 'logout_user_admin', text: 'Deslogar usuário do starlord' },
      { value: 'enable_platform_access', text: 'Ativar o acesso à plataforma' },
      { value: 'enable_admin_access', text: 'Ativar acesso ao starlord' },
      { value: 'revoke_platform_access', text: 'Revogar o acesso à plataforma' },
      { value: 'revoke_admin_access', text: 'Revogar acesso ao starlord' },
      { value: 'reset_password', text: 'Redefinir senha' },
      { value: 'inactivate_user', text: 'Inativar usuário' },
      { value: 'activate_user', text: 'Ativar usuário' }
    ],
    commandAction: []
  }),
  watch: {
    commandAction() {
      if (this.commandAction === 'reset_password') {
        const onlyExternal = this.selected.filter(user => user.mf)
        onlyExternal.forEach(user => {
          this.selected.splice(this.selected.indexOf(user), 1)
          this.$emit('update:selected', this.selected)
        })
      }
    }
  },
  methods: {
    removeSelected(id) {
      const selected = this.selected.filter(item => item._id === id)
      this.selected.splice(this.selected.indexOf(selected[0]), 1)
      this.$emit('update:selected', this.selected)
    },
    close() {
      this.commandAction = null
      this.$emit('close')
    },
    async triggerAction() {
      const userIds = this.selected.map(user => user._id)

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_TRIGGER_ADMIN_ACTION,
          variables: {
            user_id: userIds,
            command: this.commandAction
          }
        })
        this.$snackbar({ message: `Comando '${this.commandAction}' executado com sucesso` })
        this.close()
      } catch (err) {
        console.log(err)
        this.$snackbar({ message: 'Falha ao executar comando' })
      }
    }
  }
}
</script>
